/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
import { Tooltip } from '@discretize/gw2-ui-new'
import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import GitHubIcon from '@mui/icons-material/GitHub'
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import ConditionalWrapper from '../../components/ConditionalWrapper'
import Layout from '../../components/Layout'
import GuideHeader from '../../components/navigation/GuideHeader'
import Link from '../../components/navigation/Link'
import SeoHeader from '../../components/SeoHeader'

const layout = {
  SeoProps: {
    title: 'Contributors',
    description:
      'All people involved in keeping the Discretize website running!',
  },
  ContainerProps: {
    paper: true,
  },
}

const useStyles = makeStyles()((theme) => ({
  avatarRoot: {},
  avatarCard: {
    borderRadius: '0 0 15px 15px',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(8),
    textAlign: 'center',
    height: 200,
  },
  top: {
    position: 'relative',
  },
  topImage: {
    borderRadius: '15px 15px 0 0',
    height: 100,
  },
  topAvatar: {
    position: 'absolute',
    top: 100,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 100,
    height: 100,
  },
  topGithubIcon: {
    position: 'absolute',
    top: `${100 + theme.spacing(1)}px`,
    right: theme.spacing(1),
    width: 24,
    height: 24,
    color: 'white',
  },
}))

const discretizeMembers = [
  'gw2princeps',
  'jetrell98',
  'DxCx',
  'Narthex0',
  'Jonnnnny',
  'KarelGeyer',
  'Bogya',
  'Modmr3',
  'KnutHunter',
  'Delay67',
  'potatoslayer2002',
  'alxfox',
  'Metagame',
  'marcustyphoon',
  'Seatek',
  'Echo',
]

function ContributorCard({
  classes,
  login,
  avatar_url,
  contributions,
  extraInfo,
  html_url,
}) {
  const {
    backgroundImage1: {
      childImageSharp: { gatsbyImageData: img1 },
    },
    backgroundImage2: {
      childImageSharp: { gatsbyImageData: img2 },
    },
  } = useStaticQuery(graphql`
    {
      backgroundImage1: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "background.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      backgroundImage2: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "hero_twilight-oasis.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <Grid item xs={12} sm={6} md={3}>
      <ConditionalWrapper
        condition={html_url}
        wrapper={(child) => <Link to={html_url}>{child}</Link>}
      >
        <div className={classes.top}>
          <GatsbyImage
            className={classes.topImage}
            image={discretizeMembers.includes(login) ? img1 : img2}
            alt=""
          />
          <Avatar className={classes.topAvatar} src={avatar_url} />

          {html_url && <GitHubIcon className={classes.topGithubIcon} />}
        </div>
      </ConditionalWrapper>

      <Paper className={classes.avatarCard}>
        <Typography>{login}</Typography>
        <Typography variant="caption">
          {discretizeMembers.includes(login) ? (
            <>Member of Discretize [dT]</>
          ) : (
            <>External Contributor</>
          )}
        </Typography>
        {extraInfo}
        {contributions && (
          <>
            <Box m={4} />
            <Tooltip content="Lines added">
              <Chip
                icon={<AddIcon />}
                style={{ marginBottom: 8 }}
                color="primary"
                label={contributions.a}
              />
            </Tooltip>{' '}
            <Tooltip content="Lines removed">
              <Chip
                style={{ marginBottom: 8 }}
                icon={<DeleteForeverIcon />}
                label={contributions.d}
              />
            </Tooltip>
          </>
        )}
      </Paper>
    </Grid>
  )
}

function ContributorsPage() {
  const { classes } = useStyles()
  const [state, setState] = useState({
    contributors: [
      {
        login: 'jetrell98',
        avatar_url: 'https://avatars.githubusercontent.com/u/13438341?v=4',
      },
      {
        login: 'Ascers',
        avatar_url: '',
      },
      {
        login: 'Janitsu',
        avatar_url: 'https://avatars.githubusercontent.com/u/8763688?v=4',
      },
      {
        login: 'Seatek',
        avatar_url: '',
      },
      {
        login: 'ArisuZero',
        avatar_url: '',
      },
      {
        login: 'Echo',
        avatar_url: 'https://avatars.githubusercontent.com/u/29819976?v=4',
      },
    ],
    organisations: [],
  })

  /*
  React.useEffect(() =>
    axios
      .get(
        'https://api.github.com/repos/discretize/discretize-guides/stats/contributors',
        {
          headers: {
            Authorization: `token `,
          },
        },
      )
      .then((res1) => {
        const date = new Date()
        date.setFullYear(date.getFullYear() - 1)
        const cutoff = date.getTime() / 1000

        const contributors = res1.data
          .map(({ total, weeks, author: { login, avatar_url, html_url } }) => {
            return {
              total,
              login,
              avatar_url,
              html_url,
              contributions: weeks
                .filter((week) => week.w > cutoff)
                .reduce((prev, curr) => ({
                  a: prev.a + curr.a,
                  d: prev.d + curr.d,
                })),
            }
          })
          .filter((contributor) => contributor.contributions.a)
          .sort((e1, e2) => {
            return e2.contributions.a - e1.contributions.a
          })

        setState({
          ...state,
          contributors,
        })
      })
  }, [])
  */

  return (
    <>
      <GuideHeader title="Contributors" date={new Date()} />

      <Typography paragraph variant="body2">
        There are a lot of people involved in writing and auditing the content
        that can be found on this website. Discretize is always open for
        external contributors or developers. Learn more about the contribution
        process <Link to="/contribute">here</Link>.
      </Typography>

      <Typography variant="h5">Active Development</Typography>
      <Typography paragraph variant="body2">
        These people are maintaining discretize projects in their free time.
        They are the ones that implement new features, refactor the code, and
        provide technical support when necessary.
      </Typography>

      <Grid container className={classes.avatarRoot} spacing={5}>
        <ContributorCard
          classes={classes}
          login="gw2princeps"
          avatar_url="https://avatars.githubusercontent.com/u/21026467?v=4"
          html_url="https://github.com/gw2princeps"
          extraInfo={
            <Box m={2}>
              <Typography variant="caption">
                <Link to="https://www.discretize.eu">www.discretize.eu</Link>,{' '}
              </Typography>
              <Typography variant="caption">
                <Link to="https://github.com/discretize/discretize-gear-optimizer">
                  Gear Optimizer
                </Link>
                ,{' '}
              </Typography>
              <Typography variant="caption">
                <Link to="https://github.com/discretize/discretize-ui/tree/main/gw2-ui">
                  gw2-ui
                </Link>
                ,{' '}
              </Typography>
              <Typography variant="caption">
                <Link to="https://github.com/discretize">
                  various other projects
                </Link>{' '}
              </Typography>
            </Box>
          }
        />
        <ContributorCard
          classes={classes}
          login="marcustyphoon"
          avatar_url="https://avatars.githubusercontent.com/u/8336245?v=4"
          html_url="https://github.com/marcustyphoon"
          extraInfo={
            <Box m={2}>
              <Typography variant="caption">
                <Link to="https://www.discretize.eu">www.discretize.eu</Link>,{' '}
              </Typography>
              <Typography variant="caption">
                <Link to="https://github.com/discretize/discretize-gear-optimizer">
                  Gear Optimizer
                </Link>
              </Typography>
            </Box>
          }
        />
      </Grid>

      <Box m={2} />
      <Divider />
      <Typography variant="h5">Content creation & moderation</Typography>
      <Typography paragraph variant="body2">
        This is a list of all the people who contributed to{' '}
        <Link to="https://github.com/discretize/discretize-guides">
          the guides
        </Link>{' '}
        in the past 12 month.
      </Typography>

      <Grid container className={classes.avatarRoot} spacing={5}>
        {state.contributors.map((contributor) => (
          <ContributorCard
            {...contributor}
            key={contributor.login}
            classes={classes}
          />
        ))}
      </Grid>

      <Box m={2} />
      <Divider />

      <Typography variant="h5">Honorary Contributors</Typography>
      <Typography paragraph variant="body2">
        These people supported Discretize in the past, but are not actively
        involved any longer. They still serve as advisors at times.
      </Typography>

      <Grid container className={classes.avatarRoot} spacing={5}>
        <ContributorCard
          login="Metagame"
          classes={classes}
          avatar_url="https://avatars.githubusercontent.com/u/20967811?v=4"
          extraInfo={
            <Box m={2}>
              <Typography variant="caption">
                <Link to="https://www.discretize.eu">www.discretize.eu</Link>,{' '}
              </Typography>
              <Typography variant="caption">
                <Link to="https://github.com/discretize/discretize-gear-optimizer">
                  Gear Optimizer
                </Link>
                ,{' '}
              </Typography>
              <Typography variant="caption">
                <Link to="https://github.com/ManuelHaag/gw2-ui">gw2-ui</Link>
              </Typography>
            </Box>
          }
        />
        <ContributorCard
          login="DxCx"
          classes={classes}
          avatar_url="https://avatars.githubusercontent.com/u/1080411?v=4"
          extraInfo={
            <Box m={2}>
              <Typography variant="caption">
                <Link to="https://www.discretize.eu">www.discretize.eu</Link>
              </Typography>
            </Box>
          }
        />
      </Grid>
    </>
  )
}

export default function ContributorsPageWrapper({ location }) {
  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <ContributorsPage />
    </Layout>
  )
}
