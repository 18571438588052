import React from 'react'
import { Helmet } from 'react-helmet-async'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        titleTemplate
        description
        baseUrl
        imagePath
      }
    }
  }
`

function SeoHeader(props) {
  const { site } = useStaticQuery(query)

  const {
    description,
    imagePath,
    title: defaultTitle,
    titleTemplate,
    path,
    baseUrl,
  } = { ...site.siteMetadata, ...props }

  const title = props.title
    ? titleTemplate.replace('%s', props.title)
    : defaultTitle
  const url = baseUrl.slice(0, -1) + path
  const image = baseUrl.slice(0, -1) + imagePath

  return (
    <Helmet prioritizeSeoTags>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta key="og-image-width" property="og:image:width" content="512" />,
      <meta key="og-image-height" property="og:image:height" content="512" />,
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <script type="application/ld+json">
        {JSON.stringify([
          {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url,
            name: 'Discretize',
            alternateName: title,
          },
        ])}
      </script>
    </Helmet>
  )
}

export default SeoHeader
