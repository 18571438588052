import {
  Box,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DateIcon from '@mui/icons-material/DateRange';
import PersonIcon from '@mui/icons-material/Person';
import TimeIcon from '@mui/icons-material/Timeline';
import classNames from 'classnames';
import React from 'react';
import ConditionalWrapper from '../ConditionalWrapper';
import GW2Button from '../gw2components/GW2Button';
import Label from '../Label';
import Link from './Link';
import { useSelector } from 'react-redux';
import { getLanguage, setLanguage } from '../../state';
import { useDispatch } from 'react-redux';

const dayjs = require('dayjs');
const LocalizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(LocalizedFormat);

const useStyles = makeStyles()((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
    alignSelf: 'center',
  },
  side: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  label: {
    padding: `${theme.spacing(1)} !important`,
    width: '100%',
  },
  listItem: {
    padding: 0,
    whiteSpace: 'nowrap',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  additionalButtons: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  flexbox: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  link: {
    color: 'white !important',
  },
}));

export const API_LANGUAGES = [
  { label: 'Deutsch', api: 'de' },
  { label: 'English', api: 'en' },
  { label: 'Español', api: 'es' },
  { label: 'Français', api: 'fr' },
  { label: '中文', api: 'zh' },
];

interface GuideHeaderProps {
  className?: string;
  title: string;
  subTitle?: string;
  icon?: React.ReactNode;
  timeToRead?: number;
  date?: string;
  author?: string;
  link?: string;
  buttons?: {
    link: string;
    content: () => React.ReactNode;
  }[];
  profession?: string;
  children?: React.ReactNode;
}

function GuideHeader({
  className,
  title,
  subTitle,
  icon,
  timeToRead,
  date,
  author,
  link,
  buttons,
  profession,
  children,
}: GuideHeaderProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const language = useSelector(getLanguage);

  return (
    <div style={{ width: '100%' }}>
      <Box
        display="flex"
        p={1}
        className={classNames(className, classes.flexbox)}
        alignItems="center"
      >
        {isBigScreen && (
          <Box>
            {icon && (
              <ConditionalWrapper
                condition={link !== undefined}
                wrapper={(child) => (
                  <Link to={link || ''} className={classes.link}>
                    {' '}
                    {child}{' '}
                  </Link>
                )}
              >
                <div className={classes.icon}>{icon}</div>
              </ConditionalWrapper>
            )}
          </Box>
        )}
        <Box flexGrow={1}>
          {children || (
            <ConditionalWrapper
              condition={link !== undefined}
              wrapper={(child) => (
                <Link to={link || ''} className={classes.link}>
                  {' '}
                  {child}{' '}
                </Link>
              )}
            >
              {(title || subTitle) && (
                <div>
                  <Typography variant="h4">{title}</Typography>
                  {subTitle && (
                    <Typography variant="subtitle2">{subTitle}</Typography>
                  )}
                </div>
              )}
            </ConditionalWrapper>
          )}
        </Box>

        {buttons && !children && (
          <Box p={1} display="flex" flexDirection="row" flexWrap="wrap">
            {buttons.map((btn) => (
              <div key={btn.link} className={classes.additionalButtons}>
                <GW2Button to={btn.link} profession={profession}>
                  <Box alignItems="end">{btn.content()}</Box>
                </GW2Button>
              </div>
            ))}
          </Box>
        )}
        <FormControl variant="standard" sx={{ minWidth: 150 }}>
          <InputLabel id="demo-simple-select-label">
            Tooltip Language
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label="Language"
            onChange={(e) => dispatch(setLanguage(e.target.value))}
          >
            {API_LANGUAGES.map(({ label, api }) => (
              <MenuItem key={api} value={api}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!children && (
          <Box p={1}>
            {(timeToRead || date || author) && (
              <Label className={classes.label}>
                <List disablePadding>
                  {date && (
                    <ListItem className={classes.listItem}>
                      <DateIcon fontSize="inherit" />
                      <ListItemText
                        disableTypography
                        primary={dayjs(date).format('LL')}
                      />
                    </ListItem>
                  )}

                  {timeToRead && (
                    <ListItem className={classes.listItem}>
                      <TimeIcon fontSize="inherit" />
                      <ListItemText
                        disableTypography
                        primary={`${timeToRead} min to read`}
                      />
                    </ListItem>
                  )}

                  {author && (
                    <ListItem className={classes.listItem}>
                      <PersonIcon fontSize="inherit" />
                      <ListItemText disableTypography primary={author} />
                    </ListItem>
                  )}
                </List>
              </Label>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
}

export default GuideHeader;
